$(".js-burger").click(function() {
    $(".js-burger").toggleClass('is-open');
});


$(".search_text").click(function() {
	$(".search_container").toggleClass("search_container_slide", 600);
});

// HOME CAROUSEL
$(function() {
	$('#foo2').carouFredSel({
		auto: true,
		prev: '#prev2',
		next: '#next2',
		mousewheel: true,
		scroll: {
		duration:2500
		},
		swipe: {
			onMouse: true,
			onTouch: true
		}
	});
});

// VIDEO LIGHTBOX
$("#youtube").fancybox({
	helpers : {
		media : {}
	}
});

// QUOTES FADE
$(document).ready(function () {
    var allBoxes = $("div.home_quote").children("div");
    transitionBox(null, allBoxes.first());
});

function transitionBox(from, to) {
    function next() {
        var nextTo;
        if (to.is(":last-child")) {
            nextTo = to.closest(".home_quote").children("div").first();
        } else {
            nextTo = to.next();
        }
        to.fadeIn(500, function () {
            setTimeout(function () {
                transitionBox(to, nextTo);
            }, 5000);
        });
    }

    if (from) {
        from.fadeOut(500, next);
    } else {
        next();
    }
}

// HOME ROLLOVER
/*$(".rollover").hover(function() {
   if ($(".roll_2").hasClass("roll_show")){
   } else {
	 $(this).children("roll_2").toggleClass("roll_hide");
    $(this).children(".roll_1").toggleClass("roll_hide");
   }
 });*/

 $(".rollover2 a").hover(function() {
   if ($(".roll_4").hasClass("roll_show")){
   } else {
	 $(this).children(".icon_container").children("roll_4").toggleClass("roll_hide");
    $(this).children(".icon_container").children(".roll_3").toggleClass("roll_hide");
   }
 });

// FACTS ROLLOVER

$(".rollover, .home_roll_1").hover(function() {
   if ($(".roll_2").hasClass("roll_show")){
   } else {
	 $(this).children("roll_2").toggleClass("roll_hide");
    $(this).children(".roll_1").toggleClass("roll_hide");
   }
 });

// VEG TABS
$("#veg ul").idTabs();


// TEAM OVERLAY
$('.bar').mosaic({
	animation	:	'slide'
});

// TEAM SORT
$('#grid').mixitup();

// ADDITIONAL SERVICES FADE
$('.fade').mosaic();

// GOOGLE MAP
function initialize() {
	var latlng = new google.maps.LatLng(53.860042,-0.991731);
	var settings = {
		zoom: 16,
		center: latlng,
		mapTypeControl: true,
		mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
		navigationControl: true,
		navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
		mapTypeId: google.maps.MapTypeId.HYBRID
};
	var map = new google.maps.Map(document.getElementById("map_canvas"), settings);


		var companyPos = new google.maps.LatLng(53.860042,-0.991731);
		var companyLogo = new google.maps.MarkerImage('/webroot/img/map-icon.png',
		new google.maps.Size(45,49),
		new google.maps.Point(0,0),
		new google.maps.Point(22,49)
);
  var companyMarker = new google.maps.Marker({
		position: companyPos,
		map: map,
		icon: companyLogo,
		title:"Hartleys"
  });
  var center;
function calculateCenter() {
  center = map.getCenter();
}
google.maps.event.addDomListener(map, 'idle', function() {
  calculateCenter();
});
google.maps.event.addDomListener(window, 'resize', function() {
  map.setCenter(center);
});
	};


// BANNER HEIGHT

// var bh = $(".page_content").outerHeight();

// var nbh = bh+240
// $(".page_banner").height(nbh);
// $(".cases_banner.page_banner").height(bh);


// TIMELINE
		$(function(){
			$().timelinr({
				arrowKeys: 'true',
				autoPlay: 'true',
				autoPlayDirection: 'forward',
				startAt: 1,
				 autoPlayPause: 5000
			})
		});

// NAV RESPONSIVE
$("#menu").addClass("js").before('<div id="resp_menu"><div class="nav_fix"><img src="/webroot/img/menu.png" alt="" class="menu_icon"> MENU</div></div>');

$("#resp_menu").click(function(){
	$("#menu").toggle();
});

// REFRESH WHEN GOING FROM 960px to 959px

var i = 0;

$(function () {
    $(window).on("resize", function () {
        var windowsize = $(this).width();



        if (windowsize < 767 && i === 0) {
            i = 1;
        } else if (windowsize >= 767 && i == 1) {
            location.reload();
            i = 0;
        }



    });
});

// YOUTUBE VIDEO Z-INDEX FIX

$(document).ready(function(){
    $('iframe').each(function(){
          var url = $(this).attr("src");
          var char = "?";
          if(url.indexOf("?") != -1){
                  var char = "&";
           }

          $(this).attr("src",url+char+"wmode=transparent");
    });
});


/* ADDS ie10 class to html if ie10 */

	if ($.browser.msie && $.browser.version == 10) {
  $("html").addClass("ie10");
}


// INSTAGRAM
	//{{likes}} {{comments}}
 var userFeed = new Instafeed({
        get: 'user',
        userId: 1575784949,
        accessToken: '1575784949.cf0499d.ea890085ebd341d0bc4d529cc4a33a56',
        limit: 1,
        resolution: 'low_resolution',
        template: '<a href="{{link}}" target="_blank"><img src="{{image}}" /></a> <div class="insta_footer"><a href="{{link}}" target="_blank">Posted <div id="insta_time">{{model.created_time}}</div></a><p style="position:absolute; right:5px; bottom:-20px;"><a href="http://instagram.com/hartleysfrozenveg/" target="_blank">#HartleysFrozenVeg</a></p></div>',
        after: function() {
	        var contents = document.getElementById("insta_time").innerHTML;
	        var time = new Date(contents*1000);
	        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	        var month = months[time.getMonth()];
	        var date = time.getDate();
	        var formatted = date + " " + month;
	        document.getElementById("insta_time").innerHTML = formatted;
	    }
    });
    userFeed.run();
// TRUNCATE INSTAGRAM TEXT
$(window).load(function() {
var module = document.getElementById("truncate");
	$clamp(module, {clamp: 3});
});
